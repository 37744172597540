import { useRef, useReducer, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import API from '../../components/api';

import Button from '../../elements/button';
import ErrorMessage from '../../elements/error-message';
import Title from '../../elements/title';
import Table from '../../elements/table';
import { FadedText, LABELS, StatusText } from './utils';

import './new-report-generation-status.sass';

function NewReportGenerationStatus(props) {

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      deleting: false,
      feedbackMessage: '',
      reportStatus: [],
      loading: false,
    }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    handleSearch();

    return () => (isMountedRef.current = false);
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'month',
        title: 'Period',
        align: 'center',
      },
      {
        id: 'creation',
        title: 'Created At',
        align: 'center',
      },
      // {
      //   id: 'reportId',
      //   title: 'Report',
      //   align: 'center',
      // },
      {
        id: 'deviceId',
        title: 'Device',
        align: 'center',
      },
      {
        id: 'integratorId',
        title: 'Integrator',
        align: 'center',
      },
      {
        id: 'status',
        title: 'Status',
        align: 'center',
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!state.reportStatus.length) {
      return [];
    }

    let currentDate = moment(state.reportStatus[0].period).toDate();
    const lastDate = moment(state.reportStatus[state.reportStatus.length-1].period);

    const allMonthsInBetween = [moment(currentDate).format('YYYY-MM-DD')];

    while(!moment(currentDate).isSame(lastDate, 'month')) {
      currentDate = moment(currentDate).subtract(1, 'month').toDate();
      allMonthsInBetween.push(moment(currentDate).format('YYYY-MM-DD'));
    }

    const statusData = [];

    allMonthsInBetween.forEach((month) => {
      const monthData = state.reportStatus.filter(report => report.period === month);

      if (monthData.length) {
        const newData = monthData.map(data => ({
          ...data,
          month: moment(month).format('YYYY/MM'),
          creation: moment(data.createdAt).utc().format('YYYY-MM-DD HH:mm:ss'),
          status: <StatusText text={LABELS[data.status]} type={data.status === 'ERROR' ? 'error': 'success'} />,
        }));
        statusData.push(...newData);
      } else {
        statusData.push({
          id: `blank-month-${month}`,
          month: <FadedText text={moment(month).format('YYYY/MM')} />,
          creation: <FadedText text='-' />,
          reportId: <FadedText text='-' />,
          deviceId: <FadedText text='-' />,
          integratorId: <FadedText text='-' />,
          status: <FadedText text='-' />,
        });
      }
    });

    return statusData;
  }, [state.reportStatus]);

  const handleSearch = () => {
    setState({ loading: true });

    API.SENSORS.REPORTS_GENERATION_STATUS(
      props.id,
      props.integratorId,
    ).then((response) => {
      if (!isMountedRef.current) {
        return;
      }

      setState({
        reportStatus: response.data || [],
        loading: false,
      });
    }).catch(_ => {
      setState({
        loading: false,
        feedbackMessage: (
          <ErrorMessage message="Could not retrieve report generation status. Please, try again or contact support." />
        ),
      });
    });
  };

  return (
    <div id="new-report-generation-status-page">
      {state.feedbackMessage}
      <div>
        <Title element="h6" text="New Report Generation Status" />

        <Table
          columns={columns}
          data={data}
          isLoading={state.loading}
        />

        <div className="btn-actions">
          <Link
            to={{
              pathname: props.integratorId
                ? `/smart-meter/${props.integratorId}/${props.id}/admintools`
                : `/sensor/${props.id}/admintools`,
              state: 'admintools',
            }}
          >
            <Button disabled={state.loading} label="Back" loading={false} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewReportGenerationStatus;
