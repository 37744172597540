import { useRef, useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import API from '../../components/api';
import { isAdmin, isReseller, isBusiness } from '../../components/utils';

import AdminTools from '../admin-tools/admin-tools';
import AppliancesPage from './appliances-page';
import SmartMeterDetailsNav from './smart-meter-details-nav';
import PropertyDetails from './property-details';
import SensorStatus from './sensor-status';
import TariffPage from './tariff';
import UserDetails from './user-details';
import PasswordGenerate from '../admin-tools/password/password-generate';
import PasswordReset from '../admin-tools/password/password-reset';
import EmailReportCreateSend from '../email-report/email-report-create-send';
import EmailReportHistory from '../email-report/email-report-history';
import SmartMeterApp from './smart-meter-app';
import NewReportGenerationStatus from '../new-report-generation/new-report-generation-status';

import ErrorMessage from '../../elements/error-message';
import Loading from '../../elements/loading';

import './smart-meter-details.sass';

const ACCOUNT_DATA_ERROR_MSG =
  'An error occurred while fetching device data. Please try again later or contact support.';

function SmartMeterDetails(props) {
  const location = useLocation();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      client: {},
      clientCopy: {},
      clientEditLoading: false,
      clientFieldsWithError: [],
      integratorId: null,
      editing: false,
      feedbackMessage: '',
      errorMessage: '',
      accountFetchError: false,
      loading: true,
      report: {
        loading: true,
        enabled: false,
        layout: '',
      },
      propertyDetails: {
        loading: true,
        bedroom_amount: 0,
        home_size: 0,
        home_type: '',
        home_usage_type: '',
        people: [
          { age_range: "ZERO_TO_FIVE", amount: 0 },
          { age_range: "SIX_TO_EIGHTEEN", amount: 0 },
          { age_range: "ADULT", amount: 0 },
          { age_range: "PENSIONER", amount: 0 },
        ],
        people_amount: 0,
        people_amount_in_day_time: 0,
        power_generator_source: '',
        property_type: '',
        version: '1.0',
      },
    }
  );
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    loadClient();

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    const id = parseInt(props.clientId, 1);
    const integratorId = parseInt(props.integratorId, 1);
    if (
      id &&
      id !== state.client.id &&
      integratorId &&
      integratorId !== state.integratorId
    ) {
      clientSet(id, integratorId);
    }
  }, [props.clientId, props.integratorId]);

  const loadClient = (onlyClient = false) => {
    clientSet(props.clientId, props.integratorId, onlyClient);
  };

  const propertyChanged = (property) => setState({ property: property });

  const clientSet = (id, integratorId, onlyClient) => {
    API.ACCOUNTS.SMART_METER_DETAILS(id, integratorId)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        if (response.data) {
          let client = {
            id: id,
            account: response.data.account,
            property: response.data.property,
          };
          setState({
            client: client,
            clientCopy: clone(client),
            loading: false,
          });
          if (!onlyClient) {
            emailReportSet(client.property.sensorId, integratorId);
            propertyDetailsSet(client.property.sensorId, integratorId);
          }
        } else {
          throw new Error(
            'A data inconsistency was found while fetching device data.'
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setState({
          loading: false,
          errorMessage: <ErrorMessage message={ACCOUNT_DATA_ERROR_MSG} />,
          accountFetchError: true,
        });
      });
  };

  const emailReportSet = (id, integratorId) => {
    API.SMART_METERS.DETAILS(id, integratorId).then((response) => {
      if (!isMountedRef.current) {
        return;
      }

      let report = {
        loading: false,
        enabled: response.data.receiveReportEmail,
        layout: response.data.reportLayout,
      };
      setState({ report: report });
    });
  };

  const propertyDetailsSet = (id, integratorId) => {
    API.SMART_METERS.PROPERTY_DETAILS.GET(id, integratorId)
      .then((response) => {
        if (!isMountedRef.current) {
          return;
        }

        const hasPeople = Boolean(response.data?.people?.length);

        let details = {
          exists: true,
          loading: false,
          bedroom_amount: 0,
          home_size: 0,
          home_type: '',
          home_usage_type: '',
          people_amount: 0,
          people_amount_in_day_time: 0,
          power_generator_source: '',
          property_type: '',
          version: '1.0',
          ...response.data,
          ...(hasPeople &&
            { people:  [
              { age_range: "ZERO_TO_FIVE", amount: 0 },
              { age_range: "SIX_TO_EIGHTEEN", amount: 0 },
              { age_range: "ADULT", amount: 0 },
              { age_range: "PENSIONER", amount: 0 },
              ].reduce((acc, range) => {
                const prev = response.data.people.find(r => r.age_range === range.age_range);
                const ageRangeData = { ...range, amount: prev?.amount ?? 0 };
                return [ ...acc, ageRangeData ];
              }, []),
            }
          ),
        };
        setState({ propertyDetails: details });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          let details = state.propertyDetails;
          details.exists = false;
          details.loading = false;
          setState({ propertyDetails: details });
        } else console.error(error);
      });
  };

  const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  const isAdminBusinessOrResellerUser = () => {
    return isAdmin() || isReseller() || isBusiness();
  };

  const { client, propertyDetails } = state;
  const { loadingSensor, integratorId } = props;
  const locationState = location.state;
  if (state.loading) return <Loading />;

  return (
    <div id="smart-meter-client-details">
      {!state.accountFetchError ? (
        <SmartMeterDetailsNav
          client={client}
          property={propertyDetails}
          integratorId={integratorId}
        />
      ) : null}

      <div id="smart-meter-client-details-columns">
        {!locationState || locationState === 'account' ? (
          <div>
            {state.accountFetchError ? (
              state.errorMessage
            ) : (
              <>
                {isAdminBusinessOrResellerUser() ? (
                  <UserDetails
                    client={client}
                    editing={state.editing}
                    feedbackMessage={state.feedbackMessage}
                    fieldsWithError={state.clientFieldsWithError}
                    loading={state.clientEditLoading}
                    isSmartMeter={true}
                    integratorId={integratorId}
                  />
                ) : null}

                {loadingSensor ? (
                  <Loading />
                ) : (
                  <SensorStatus sensor={props.sensor} />
                )}
              </>
            )}
          </div>
        ) : null}
        {locationState === 'tariff' ? (
          <TariffPage
            id={client.id}
            integratorId={integratorId}
            isSmartMeter={true}
          />
        ) : null}
        {locationState === 'admintools' ? (
          <AdminTools
            id={client.id}
            privacy={client.privacy}
            category={client.stock && client.stock.category}
            isSmartMeter={true}
            integratorId={integratorId}
            account={client.account}
          />
        ) : null}
        {locationState === 'smartmeterapp' ? (
          <SmartMeterApp
            client={client}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}
        {locationState === 'newreportgenerationstatus' ? (
          <NewReportGenerationStatus
            id={client.id}
            integratorId={integratorId}
          />
        ) : null}
        {locationState === 'propertydetails' ? (
          <PropertyDetails
            client={client}
            property={propertyDetails}
            propertyChanged={propertyChanged}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}
        {locationState === 'appliances' ? (
          <AppliancesPage
            id={client.property.sensorId}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}

        {locationState === 'passwdreset' ? (
          <PasswordReset
            id={client.id}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}
        {locationState === 'passwdgenerate' ? (
          <PasswordGenerate
            id={client.id}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}

        {locationState === 'periodicreporthistory' ? (
          <EmailReportHistory
            id={client.id}
            isSmartMeter={true}
            integratorId={integratorId}
          />
        ) : null}
        {locationState === 'periodicreportcreate' ||
        locationState === 'periodicreportsend' ? (
          <EmailReportCreateSend
            client={client}
            mode={locationState}
            sensor={props.sensor}
            isSmartMeter={true}
            integratorId={integratorId}
            activatedAt={state.report.activatedAt}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SmartMeterDetails;
