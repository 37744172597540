export function FadedText(props) {
  return <span style={{ color: '#cecece', fontSize: 12}}>{props.text}</span>
}

export function StatusText(props) {
  return (
    <span
      style={{
        color: props.type === 'error' ? 'rgba(221, 44, 0, 0.87)' : '#4CAF50',
        fontSize: 12,
      }}
    >
      {props.text}
    </span>
  );
}

export const LABELS = {
  'SENT': 'Sent',
  'ERROR': 'Error',
  'CREATED': 'Created',
};